import { message, Modal } from "antd";
import React, { useState } from "react";
import { Terms } from "../../Terms";
import EmailSent from "../EmailSent";
import Spinner from "../../Spinner";
import { downloadTermsItems } from "./constant";
import "./index.scss";
import { triggerDownload } from "../../../Api/requests";

const DownloadModal = ({ id, folderId, onClose }) => {
  const [mode, setMode] = useState("init");

  const onSubmit = async () => {
    setMode("loading");
    try {
      await triggerDownload(id, folderId);
      setRetryCounter(); //sends an event to trigger timer
      setMode("email"); //email screen will be displayed on success
    } catch (error) {
      message.error("Download could not be triggered.");
      onClose(); //auto-close modal on error
    }
  };

  const setRetryCounter = () => {
    document.dispatchEvent(new Event("start-timer"));
  };

  const renderMode = () => {
    //this renders multiple screens based on the mode active
    switch (mode) {
      case "init":
        return <Terms onSubmit={onSubmit} termsContent={downloadTermsItems} />;
      case "loading":
        return <Spinner tip="Loading..." />;
      case "email":
        return <EmailSent />;
      default:
        break;
    }
  };

  return (
    <Modal
      visible={true}
      title="Download as a zip"
      footer={null}
      onCancel={onClose}
    >
      <div className="wrapper-main terms-main">{renderMode()}</div>
    </Modal>
  );
};

export default DownloadModal;
