import React, { Component } from "react";
import CrownedCard from "../../Layout/CrownedCard";
import { getFile, getSharedFile } from "../../Api/requests";
import Spinner from "../Spinner";
import { Modal } from "antd";
import { Consumer } from "../../Context/MainContext";
import ViewerWrapper from "../ViewerWrapper";
import { renderTabIcon } from "../../Utils";

/*
 * This the wrapper that initates a get call to get the basic details of the file.
 * Like type, extension name etc
 * Here we also check for whether it's an open with or opening a shared link. API's differ based on that
 */

export default (props) => (
  <Consumer>{(values) => <Index {...props} {...values} />}</Consumer>
);

export class Index extends Component {
  constructor(props) {
    super(props);

    this.state = {
      password: "",
      id: null,
      mode: "init",
      data: {},
      isOwner: false,
    };
  }

  componentDidMount = () => {
    let id = null;
    const params = this.props.match.params;
    if (params) {
      id = params.id;
      const pathname = window.location.pathname;
      //check for open with action
      const isOwner = pathname.includes("web_editor");

      this.setState({ id, isOwner });
      this.fetchData(id, isOwner);
    }
  };

  fetchData = (id, isOwner) => {
    const { setTitle } = this.props;
    let api = getSharedFile;
    //if owner, use open file api
    if (isOwner) {
      api = getFile;
    }
    api(id).then((data) => {
      const { fileType, fileName, extension } = data;
      let mode = fileType;
      this.setState({ loading: false, data, mode });
      //set tab title and icon
      setTitle(fileName);
      renderTabIcon(extension);
    });
  };

  handleRender = () => {
    const { mode, data, isOwner, id } = this.state;
    switch (mode) {
      case "init":
        return (
          <CrownedCard>
            <Spinner tip="Loading..." />
          </CrownedCard>
        );
      case "DOCUMENT":
      case "IMAGE":
      case "VIDEO":
      case "FOLDER":
        return <ViewerWrapper {...{ ...data, isOwner, id }} />;
      default:
        Modal.warning({
          title: "File Unsupported",
          content: "The file you are trying to open is not supported by Anchor",
        });
        return <></>;
    }
  };

  render() {
    return this.handleRender();
  }
}
