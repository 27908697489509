import { Button, Alert } from "antd";
import React from "react";
import { termsItems } from "./constant";
import "./index.scss";
export const Terms = ({ onSubmit, termsContent }) => {
  const onClose = () => {
    window.close();
  };
  const termsData = termsContent ? termsContent : termsItems;
  return (
    <div className="wrapper-main terms-main">
      <h2 style={{ marginTop: termsContent ? 0 : 10 }}>
        Access Authorization Terms & Conditions
      </h2>
      <span>Please read the following and confirm your consent:</span>
      <ul className="terms-items custom-scroll">
        {termsData.map((item, i) => (
          <li key={i}>{item}</li>
        ))}
      </ul>
      <div className="terms-buttons">
        <Button type="primary" className="rounded-button" onClick={onSubmit}>
          Accept and continue
        </Button>
      </div>
      <div className="terms-alert">
        <Alert
          message="If you do not accept the terms and conditions, please close the browser tab or window"
          type="warning"
        />
      </div>
    </div>
  );
};
