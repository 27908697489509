import React from "react";

export default () => {
  const style = {
    color: "#7587a6",
    fontWeight: 600,
  };
  return (
    <div className="shared-item-main" style={{ height: 40, margin: 0 }}>
      <div className="icon-and-name" style={style}>
        <span className="item-name">Name</span>
      </div>
      <div className="item-details" style={style}>
        <span className="item-size">Size</span>
        <span className="item-modified">Last Modified</span>
        <span className="item-by">Uploaded By</span>
        <div
          style={{ visibility: "hidden" }}
          className={`open-button enabled-button`}
        ></div>
      </div>
    </div>
  );
};
