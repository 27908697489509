import React, { Component } from "react";
import { Result } from "antd";
import postscribe from "postscribe";

export default class extends Component {
  // componentDidMount = () => {
  //   postscribe(
  //     "#root",
  //     `    <script type="text/javascript">var $zoho=$zoho || {};$zoho.salesiq = $zoho.salesiq || {widgetcode:"243f51070589e7d9f36fa29f2cd33cfb8653141cc9e2093615f7ef56820fa3d3", values:{},ready:function(){}};var d=document;s=d.createElement("script");s.type="text/javascript";s.id="zsiqscript";s.defer=true;s.src="https://salesiq.zoho.com/widget";t=d.getElementsByTagName("script")[0];t.parentNode.insertBefore(s,t);d.write("<div id='zsiqwidget'></div>");</script>`
  //   );
  // };
  // closeWindow = () => {
  //   window.open("", "_parent", "");
  //   window.close();
  // };
  render() {
    let title = "Error",
      subTitle = "Sorry, something went wrong.";

    //getting error from session storage and parsing it
    let errorFromStorage = JSON.parse(sessionStorage.getItem("da-error"));

    if (errorFromStorage && errorFromStorage !== "undefined") {
      title = errorFromStorage?.heading;
      subTitle = errorFromStorage?.description;
    }

    //returns html for subtitle to be dangerously set
    const getSub = { __html: subTitle };

    return (
      <div>
        <Result status="500" title={title} />
        <div
          style={{
            textAlign: "center",
            color: `rgba(0, 0, 0, 0.45)`,
            fontSize: 15,
          }}
          dangerouslySetInnerHTML={getSub}
        ></div>
      </div>
    );
  }
}
