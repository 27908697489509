import React from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { Select, Space, DatePicker, Tooltip, Button, Tag, Divider } from "antd";
import moment from "moment";
import { firstLetterCaps } from "../../../Utils";
import { CopyFilled, CopyOutlined } from "@ant-design/icons";

const { Option } = Select;

export default ({
  shareURL,
  activePermission,
  permissions,
  expiry,
  handleAction,
  handleDateChange,
}) => {
  const isLong = shareURL.length > 40,
    cutURl = shareURL.slice(0, 39);

  return (
    <>
      <div>
        <label>URL</label>
        <div className="share-link">
          <span id="share-link">
            <Tooltip title={shareURL}>
              {isLong ? `${cutURl}...` : shareURL}
            </Tooltip>
          </span>
          <CopyToClipboard text={shareURL}>
            <Button icon={<CopyOutlined />}>Copy link</Button>
          </CopyToClipboard>
        </div>
        <Select
          value={activePermission}
          placeholder="Action"
          style={{ width: "100%" }}
          onChange={handleAction}
        >
          {permissions.map((item) => {
            const lowerCaseItem = item.toLowerCase();
            return (
              <Option key={lowerCaseItem}>
                {firstLetterCaps(lowerCaseItem)}
              </Option>
            );
          })}
        </Select>
      </div>

      <Space direction="vertical" style={{ marginTop: 10, width: "100%" }}>
        <label>Link Expiry</label>
        <DatePicker
          autoComplete="new-password"
          value={expiry ? moment(expiry) : null}
          format="YYYY-MM-DD"
          style={{ width: "100%" }}
          showToday={false}
          disabledDate={(current) => {
            /**
             * Check if the date falls in between today and the next 60 days
             * Disable all which doesn't fall in the range
             */
            const fallsInRange = moment(current).isBetween(
              moment().endOf("day"),
              moment().add(60, "days")
            );
            return current && !fallsInRange;
          }}
          onChange={handleDateChange}
        />
        <div className="premission-desc">
          <Tag color="blue">Note :</Tag>
          By default, the link will have an expiry of 60 days.
        </div>
      </Space>
    </>
  );
};
