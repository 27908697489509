import React, { Component } from "react";
import { Progress, List, Button } from "antd";
import CrownedCard from "../../Layout/CrownedCard";
import { getLocation } from "../../Utils";
import {
  encryptFile,
  getOperationProgress,
  decryptFile,
} from "../../Api/requests";
import "./index.scss";
import ProgressItem from "./ProgressItem";
import { Consumer } from "../../Context/MainContext";
import ProgressInfo from "./ProgressInfo";
import { DownloadOutlined } from "@ant-design/icons";

export default (props) => (
  <Consumer>{(values) => <Index {...props} {...values} />}</Consumer>
);

export class Index extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      location: null,
      id: null,
      items: [],
      totalFiles: 0,
      encrypt: true,
      downloadAsZip: false,
      downloadURL: null,
    };
  }

  componentDidMount = () => {
    let id = null;
    const { setTitle, match } = this.props;

    if (match.params?.type) {
      const encrypt = match.params.type === "file-encrypt";
      id = match.params.id;
      this.setState({ id, encrypt }, this.fetchLocation);
      setTitle(encrypt ? "Encryption" : "Decryption");
    } else {
      //this section is for download as zip.
      //it captures id from the url
      if (match.params?.id) {
        id = match.params.id;
      }
      this.setState(
        { id, encrypt: false, downloadAsZip: true },
        this.fetchLocation
      );
      setTitle("Download as zip");
    }
  };

  fetchLocation = () => {
    getLocation()
      .then((data) => {
        this.fetchData(data.coords);
      })
      .catch((err) => {
        this.fetchData(null);
      });
  };

  fetchData = (location) => {
    const { id, encrypt, downloadAsZip } = this.state;
    let latitude = null,
      longitude = null;

    if (location) {
      latitude = location.latitude;
      longitude = location.longitude;
    }
    const payload = {
      latitude,
      longitude,
    };
    let api = null; //by default

    if (downloadAsZip) {
      //in case of zip download, the below api will be used
      this.pollProgress();
    } else {
      //encryption decryption switcher
      api = encrypt ? encryptFile : decryptFile;
      api(id, payload).then((res) => {
        const { pollId } = res;
        if (pollId) {
          this.pollProgress();
        }
      });
    }
  };

  pollProgress = () => {
    const { id, downloadAsZip } = this.state;
    if (id) {
      getOperationProgress(id).then((res) => {
        if (res) {
          const { items, totalFiles } = res;

          if (res?.downloadUrl) {
            //incase if there is a downloadurl
            this.setState({ downloadURL: res?.downloadUrl });
          } else {
            //if not, then polls for url
            if (totalFiles !== items.length) {
              setTimeout(() => {
                this.pollProgress();
              }, 1000);
            } else {
              //This will be triggered when the total files ==  no. of items

              if (downloadAsZip) {
                //Only comes here if this is a download as zip ops.

                /** CONTEXT:
                 * According to the above logic, the polling stops when no. of items == total files.
                 * That condition is true for x-crypt ops. But for download as zip fn, the polling
                 *  has to be done one more time to get the downloadUrl.
                 * It enters the below if, if there is downloadUrl or goes again to poll one more
                 *  time innorder to get the URL.
                 */

                if (res?.downloadUrl) {
                  this.setState({ downloadURL: res?.downloadUrl });
                } else {
                  setTimeout(() => {
                    this.pollProgress();
                  }, 1000);
                }
              }
            }
          }

          this.setState({ items, totalFiles });
        }
      });
    }
  };

  render() {
    const { items, totalFiles, encrypt, downloadURL, downloadAsZip } =
      this.state;
    const errorFree = items.reduce((sum, next) => sum && next.success, true);
    const errorCount = items.filter((item) => item.success === false).length;
    const percent = parseInt((items.length / totalFiles) * 100);
    const completed = percent === 100;

    let status = "active";

    if (completed) {
      if (!errorFree) {
        status = errorCount === totalFiles ? "exception" : "warning";
      } else {
        status = "success";
      }
    }

    return (
      <CrownedCard maxWidth={800}>
        <div className="progress-main">
          <div className="progressbar-cont">
            <ProgressInfo {...{ status, percent }} />
          </div>
          <div className="divider"></div>
          <div className="progress-files">
            <List
              header={
                <div className="title">
                  {completed
                    ? `Completed processing. You may close this
                  window.`
                    : `Processing ${totalFiles} Files. Please do not close this
                  window.`}
                </div>
              }
              dataSource={items}
              itemLayout="vertical"
              renderItem={(item) => (
                <List.Item>
                  <ProgressItem {...item} encrypt={encrypt} />
                </List.Item>
              )}
            />
          </div>
        </div>
        {downloadAsZip && (
          <Button
            href={downloadURL}
            target="_blank"
            disabled={!downloadURL}
            icon={<DownloadOutlined />}
            type="primary"
            shape="round"
            style={{ width: "50%", margin: "auto" }}
          >
            Download zip
          </Button>
        )}
      </CrownedCard>
    );
  }
}
