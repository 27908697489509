import React, { Component } from "react";

const MainContext = React.createContext();

export const Consumer = MainContext.Consumer;

export default class Provider extends Component {
  constructor(props) {
    super(props);

    this.state = {
      tabTitle: null,
    };
  }

  setTitle = (tabTitle) => {
    this.setState({ tabTitle });
  };

  render() {
    return (
      <MainContext.Provider value={{ ...this.state, setTitle: this.setTitle }}>
        {this.props.children}
      </MainContext.Provider>
    );
  }
}
