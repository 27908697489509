import React from "react";
import CrownedCard from "../../Layout/CrownedCard";
import Spinner from "../Spinner";

export default () => {
  return (
    <CrownedCard>
      <Spinner tip="Fetching your location and getting your document ready" />
    </CrownedCard>
  );
};
