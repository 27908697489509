import React from "react";
import {
  GoogleMap,
  Marker,
  withGoogleMap,
  withScriptjs,
} from "react-google-maps";
import "./index.scss";
import { customMarkup } from "./CustomMarker";
import { parseLocation } from "../../../Utils";

const MonitoringMap = withScriptjs(
  withGoogleMap((props) => {
    let lat = 40.67,
      lng = -73.94;
    if (props.data.length > 0) {
      const first = props.data[0];
      const {latitude, longitude} = first.location
      if (latitude) {
        lat = first.location.latitude;
      }
      if (longitude) {
        lng = first.location.longitude;
      }
    }

    return (
      <GoogleMap
        defaultZoom={5}
        defaultCenter={{ lat: parseLocation(lat), lng: parseLocation(lng) }}
      >
        {props.data.map((item, i) => {
          const numb = i + 1;
          return (
            <Marker
              position={{
                lat: parseLocation(item.location.latitude),
                lng: parseLocation(item.location.longitude),
              }}
              icon={{
                url: customMarkup(("0" + numb).slice(-2)),
              }}
            />
          );
        })}
      </GoogleMap>
    );
  })
);

export default MonitoringMap;
