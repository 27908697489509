import React, { Component } from "react";
import "./index.scss";
import { CopyToClipboard } from "react-copy-to-clipboard";
import {
  Select,
  Space,
  DatePicker,
  Switch,
  Checkbox,
  Button,
  Input,
  message,
} from "antd";
import CrownedCard from "../../Layout/CrownedCard";
import { getShareSettings, setShareSettings } from "../../Api/requests";
import Spinner from "../Spinner";
import moment from "moment";
import { firstLetterCaps, removeXSS, validateEmail } from "../../Utils";
import { Consumer } from "../../Context/MainContext";
import md5 from "md5";
import Permissions from "./Permissions";
import Properties from "./Properties";
import { checkDownloadPermissions } from "./helper";

export default (props) => (
  <Consumer>{(values) => <Index {...props} {...values} />}</Consumer>
);

export class Index extends Component {
  constructor(props) {
    super(props);

    this.state = {
      password: null,
      id: null,
      loading: true,
      activePermission: "view",
      permissions: [],
      active: false,
      expiry: null,
      fileName: "",
      hasPassword: false,
      hasPasswordCopy: false,
      resetPassword: false,
      shareId: "",
      FULL_APP_HOSTNAME: "",
      authRequired: false,
      userList: "",
      sharingOptions: [],
      notifyUsers: false,
      isFolder: false,
      isDownloadable: false,
      downloadEnabled: false,
    };
  }

  componentDidMount = () => {
    const { match, setTitle } = this.props;
    let id = null;
    if (match.params) {
      id = match.params.id;
      this.setState({ id });
      this.fetchData(id);
      setTitle("Sharing");
    }
  };

  fetchData = (id) => {
    getShareSettings(id).then((data) => {
      this.setState({
        loading: false,
        ...data,
        hasPasswordCopy: data.hasPassword,
      });
      //add 60 day link expiry by default
      if (data.expiry === null) {
        this.handleDateChange(moment().add(60, "days"));
      }
    });
  };

  handleAction = (activePermission) => {
    this.setState({ activePermission });
  };

  handleDateChange = (date) => {
    //the below is to handle whne the user click clear and date becomes null
    const expiryDate = date ? date : moment().add(60, "days");
    //get time from moment date object
    const expiry = new Date(expiryDate).getTime();
    this.setState({ expiry });
  };

  validatePassword = () => {
    const { password, resetPassword, hasPassword, hasPasswordCopy } =
      this.state;

    if (resetPassword || (hasPassword && !hasPasswordCopy)) {
      if (!password || password === "") {
        message.error("The password is empty");
      } else {
        this.validateEmails();
      }
    } else {
      this.validateEmails();
    }
  };

  validateEmails = () => {
    const { authRequired, userList, isFolder, isDownloadable } = this.state;
    let errors = [],
      emails = [];
    if (authRequired) {
      const users = userList.split(",").filter((item) => item != "");
      users.forEach((item) => {
        if (validateEmail(item)) {
          emails.push(item);
        } else {
          errors.push(item);
        }
      });

      if (errors.length > 0) {
        message.error(
          "Some of the email addresses entered are incorrectly formatted"
        );
      } else {
        if (emails.length === 0) {
          message.error(
            "Please add atleast one email address to the allowed list"
          );
        } else {
          this.setState({ userList: emails.join(",") }, this.checkDownload);
        }
      }
    } else {
      this.onSave();
    }
  };

  checkDownload = () => {
    const { isFolder, downloadEnabled } = this.state;

    const resetDownload = () => {
      this.setState({ downloadEnabled: false });
    };
    //show the agreement modal only if the it's for folder sharing and only if it's checked
    isFolder && downloadEnabled
      ? checkDownloadPermissions(resetDownload, this.onSave)
      : this.onSave();
  };

  onSave = () => {
    let password = this.state.password;
    //hashing password before sending to backend
    let state = { ...this.state, password: password ? md5(password) : null };
    const id = this.state.id;
    delete state["loading"];
    delete state["id"];
    delete state["resetPassword"];
    delete state["hasPasswordCopy"];

    setShareSettings(id, state).then((data) => {
      message.success("Saved successfully!");
    });
  };

  changeState = (data) => {
    this.setState(data);
  };

  render() {
    const {
      active,
      loading,
      fileName,
      expiry,
      FULL_APP_HOSTNAME,
      shareId,
      permissions,
      activePermission,
    } = this.state;

    const shareURL =
      FULL_APP_HOSTNAME + "/api/egnyte/service/open/document/" + shareId;

    return (
      <CrownedCard>
        {loading ? (
          <Spinner tip="Loading..." />
        ) : (
          <>
            <div>
              <div className="inner">
                <span className="share-title">Share with others</span>
                <Space>
                  <label>Enable/Disable Sharing</label>
                  <Switch
                    checked={active}
                    onChange={(active) => {
                      this.setState({ active });
                    }}
                  />
                </Space>
              </div>
              <blockquote className="filename">{fileName}</blockquote>
            </div>
            {active && (
              <>
                <div
                  className="input-group"
                  style={{ display: "initial" }}
                  id="share-text"
                >
                  <Properties
                    {...{
                      shareURL,
                      activePermission,
                      permissions,
                      expiry,
                      handleAction: this.handleAction,
                      handleDateChange: this.handleDateChange,
                    }}
                  />
                  <Permissions {...this.state} setState={this.changeState} />
                </div>
              </>
            )}
            <div style={{ textAlign: "center", marginTop: "auto" }}>
              {active ? (
                <CopyToClipboard onCopy={this.validatePassword} text={shareURL}>
                  <Button type="primary" className="rounded-button">
                    Save & Copy URL
                  </Button>
                </CopyToClipboard>
              ) : (
                <Button
                  type="primary"
                  className="rounded-button"
                  onClick={this.onSave}
                >
                  Save
                </Button>
              )}
            </div>
          </>
        )}
      </CrownedCard>
    );
  }
}
