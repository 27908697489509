import { Breadcrumb, Empty, Input, Spin } from "antd";
import React, { useEffect, useState } from "react";
import { getSharedFolder, openSharedFile } from "../../Api/requests";
import CrownedCard from "../../Layout/CrownedCard";
import "./index.scss";
import SharedItem from "./SharedItem";
import md5 from "md5";
import { handleApiRes } from "./utlis";
import LoadingModal from "./LoadingModal";
import HeaderTitles from "./HeaderTitles";
import Header from "./Header";

/*
 * renders all the folder view
 */

export default ({ initData, isDownloadable, downloadEnabled, ...state }) => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(initData);
  const [rootName, setRootName] = useState(initData.name);
  const [mode, setMode] = useState("init");

  const { files, folders, totalCount, name, folderId, userInfo } = data;

  const [paths, setPaths] = useState([]);
  const [search, setSearch] = useState("");
  const [res, setRes] = useState(null);

  useEffect(() => {}, []);

  const { email, password, location, id } = state;

  //called each time when a folder/file is clicked/opened
  const getData = (itemId, isFolder, fileName) => {
    let latitude = null,
      longitude = null,
      payload = null,
      api;

    if (location) {
      latitude = location.latitude;
      longitude = location.longitude;
    }

    setLoading(true);

    //different api and paylod for a file and a folder
    if (isFolder) {
      payload = { folderId: itemId, password: password ? md5(password) : null };
      api = getSharedFolder;
    } else {
      payload = {
        password: password ? md5(password) : null,
        email,
        latitude,
        longitude,
        fileId: itemId,
      };
      api = openSharedFile;
    }
    api(id, payload).then((res) => {
      if (res) {
        handleApiRes(
          isFolder,
          res,
          setData,
          itemId,
          paths,
          setPaths,
          setLoading,
          setRes,
          fileName,
          setMode
        );
      }
    });
  };

  /*
   * Function triggered on breadcrum click.
   * it fetches the data for the respective path and also mangages the loGic for the breadcrums
   */
  const gotoPath = (folderId, index) => {
    if (folderId) {
      setPaths(paths.slice(0, index + 1));
    } else {
      setPaths([]);
    }
    getData(folderId, true);
  };

  //maps all folders and also adds a filter fn
  const folderItems = folders
    .filter(
      (item) => item.name.toLowerCase().indexOf(search.toLowerCase()) > -1
    )
    .map((item) => <SharedItem {...{ key: item.name, getData, ...item }} />);

  //maps all files and also adds a filter fn

  const fileItems = files
    .filter(
      (item) => item.name.toLowerCase().indexOf(search.toLowerCase()) > -1
    )
    .map((item) => <SharedItem {...{ key: item.name, getData, ...item }} />);

  //shread by name constructor
  const sharedBy = `${userInfo?.firstName || ""} ${userInfo?.lastName || ""}`;
  return (
    <div className="shared-folders-main">
      <CrownedCard maxWidth={"85%"}>
        {loading && <LoadingModal loading={loading} />}
        <Header
          {...{
            sharedBy,
            setSearch,
            totalCount,
            search,
            gotoPath,
            rootName,
            paths,
            id,
            folderId,
            isDownloadable,
            downloadEnabled,
          }}
        />
        <div className="file-list custom-scroll">
          <HeaderTitles />
          {folderItems}
          {fileItems}
          {folderItems.length === 0 && fileItems.length === 0 && <Empty />}
        </div>
      </CrownedCard>
    </div>
  );
};
