import { v4 as uuidv4 } from "uuid";
import { isMobile } from "react-device-detect";
import { notification } from "antd";

/*
 *  renders all the doc files
 */

export const renderViewer = ({
  extension,
  fileUrl,
  action,
  FULL_APP_HOSTNAME,
  paramId,
  encrypted,
  readOnlyFile,
  user,
  itemId,
  fileName,
}) => {
  let documentType = {
    pptx: "presentation",
    ppt: "presentation",
    odp: "presentation",
    fodp: "presentation",
    otp: "presentation",
    pot: "presentation",
    potm: "presentation",
    potx: "presentation",
    pps: "presentation",
    ppsm: "presentation",
    ppsx: "presentation",
    pptm: "presentation",
    xlsx: "spreadsheet",
    xls: "spreadsheet",
    ods: "spreadsheet",
    csv: "spreadsheet",
    fods: "spreadsheet",
    ots: "spreadsheet",
    xlsm: "spreadsheet",
    xlt: "spreadsheet",
    xltm: "spreadsheet",
    xltx: "spreadsheet",
    docx: "text",
    doc: "text",
    odt: "text",
    txt: "text",
    docm: "text",
    dot: "text",
    dotm: "text",
    dotx: "text",
    epub: "text",
    fodt: "text",
    htm: "text",
    html: "text",
    mht: "text",
    ott: "text",
    pdf: "text",
    rtf: "text",
    djvu: "text",
    xps: "text",
  };

  //checking if the format is supported by only office
  if (!documentType.hasOwnProperty(extension)) {
    const heading = "Unsupported file type",
      desc = "This file extension is not supported by our document viewer";

    window.location.href = `/error?title=${heading}&desc=${desc}`;
  } else {
    //key generation. As of now title is used as a unique key for collaboration
    const onlyofficeKey = uuidv4();

    let bannerContent = [
      {
        title: "Read Only Mode",
        desc: "File is either locked by another user or contains macros and therefore opened in read-only mode.",
      },
      {
        title: "Auto Save Mode",
        desc: "Your changes to this document are saved back to Egnyte within few seconds after you close this browser tab or window.",
      },
      {
        title: "Non-Protected File",
        desc: "This file is not Anchor protected and therefore will be saved backed to Egnyte as plain text.",
      },
      {
        title: "Read Only Mode",
        desc: "This file is opened in read-only mode.",
      },
    ];

    const mode = isMobile ? "view" : action.toLowerCase();

    let bannerItem = encrypted
      ? action === "view"
        ? bannerContent[3]
        : bannerContent[1]
      : bannerContent[2];

    if (readOnlyFile) {
      bannerItem = bannerContent[0];
    }

    if (isMobile) {
      bannerItem = bannerContent[3];
    }

    //making the file viewer container visible and making the root hidden
    const container = document.getElementById("file-viewer-container");

    container.style = `visibility: visible`;

    const name = user.firstName || user.name;

    const root = document.getElementById("root");
    root.style.overflow = "hidden";

    //callback url contructor
    let callbackUrl = `${FULL_APP_HOSTNAME}/api/egnyte/service/file-encrypt/reupload-file/${paramId}?encrypted=${encrypted}`;

    //have to send in the fileId if it's from a shared folder
    if (itemId) {
      callbackUrl = callbackUrl + `&fileId=${itemId}`;
    }
    new window.DocsAPI.DocEditor("file-viewer", {
      events: {
        onDocumentReady: () => {
          //Notification Modal
          notification.info({
            message: bannerItem.title,
            description: bannerItem.desc,
            duration: 10,
          });
        },
      },
      document: {
        fileType: extension,
        key: onlyofficeKey,
        title: fileName,
        url: fileUrl,
        permissions: {
          changeHistory: false,
          comment: false,
          download: false,
          edit: mode,
          fillForms: false,
          modifyContentControl: false,
          modifyFilter: false,
          print: false,
          review: false,
          copy: false,
        },
      },
      documentType: documentType[extension],
      editorConfig: {
        callbackUrl,
        mode,
        user: {
          name,
        },
        customization: {
          customer: {
            address: "8000 Walton Parkway, New Albany Ohio 43054",
            info: "Empowers businesses to fully control their data and automate security.",
            logo: "https://storage.googleapis.com/client-logo-images/Logo%20Files/PNG/Full%20Lockup/Anchor%20-%20Logo%20-%20Compo%202.png",
            mail: "help@anchormydata.com",
            name: "By DAtAnchor Inc",
            www: "https://anchormydata.com",
          },
          logo: {
            image:
              "https://storage.googleapis.com/client-logo-images/Logo%20Files/PNG/Full%20Lockup/Anchor%20-%20Logo%20-%20White.png",
            url: "https://www.datanchor.io",
            imageEmbedded:
              "https://storage.googleapis.com/client-logo-images/Logo%20Files/PNG/Full%20Lockup/Anchor%20-%20Logo%20-%20White.png",
          },
        },
      },
      height: "100%",
      width: "100%",
      type: isMobile ? "mobile" : "desktop",
    });
  }
};
