import { Modal } from "antd";
import React from "react";
import Spinner from "../../Spinner";
import "./index.scss";

export default ({ loading }) => {
  return (
    <Modal visible={loading} footer={null} closable={false} centered>
      <div className="loading-spinner-cont">
        <Spinner tip="Please wait. It might take a few seconds to open the item" />
      </div>
    </Modal>
  );
};
