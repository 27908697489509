import React, { Component } from "react";
import anchor from "../Res/logo_mark.svg";
import "./CrownedCard.scss";

export default class extends Component {
  render() {
    const { maxWidth } = this.props;
    return (
      <div className="ownContainer" id="password-input">
        <div className="ownCard" style={{ maxWidth }}>
          <i className="btn-floating btn-large icon-con">
            <img alt="anchor" src={anchor} width="40px" />
          </i>
          <div className="container-main">{this.props.children}</div>
        </div>
      </div>
    );
  }
}
