import React, { Component } from "react";
import { Button, Input, Typography, message } from "antd";
import auth0Client from "../../Api/Auth0";
import "./index.scss";
import { validateEmail } from "../../Utils";

const { Text } = Typography;

export default class extends Component {
  constructor(props) {
    super(props);

    this.state = {
      mode: "init",
      email: null,
      otp: null,
      hasEmail: false,
      emails: [],
      loading: false,
      count: 300,
    };

    this.interval = null;
  }

  onSendOTP = () => {
    const { email } = this.state;
    const { state } = this.props;

    if (validateEmail(email)) {
      this.setState({ mode: "next", count: 300 });
      //store url to local
      const prevURL = window.location.pathname;
      localStorage.setItem("anchorRedirectPath", prevURL);
      //save state to storage
      sessionStorage.setItem("anchorState", JSON.stringify(state));

      this.interval = setInterval(this.runTimer, 1000);

      auth0Client
        .sendOtp(email)
        .then((resp) => {
          console.log(JSON.stringify(resp));
        })
        .catch((err) => {
          sessionStorage.removeItem("anchorState");
          localStorage.removeItem("anchorRedirectPath");
        });
    } else {
      message.error("Please enter a valid email address");
    }
  };

  runTimer = () => {
    const { count } = this.state;
    if (count === 0) {
      clearInterval(this.interval);
    } else {
      this.setState((state) => ({ count: state.count - 1 }));
    }
  };

  onVerify = () => {
    const { email, otp } = this.state;
    this.setState({ loading: true });
    auth0Client
      .verifyOtp(email, otp)
      .then((resp) => {
        this.setState({ loading: false });
      })
      .catch((error) => {
        console.log("err", error);
        message.error(error.description);
        this.setState({ loading: false });
      });
  };

  render() {
    const { mode, email, otp, loading, count } = this.state;
    const { onEmailChange } = this.props;
    const login = mode === "init";

    return (
      <div className={`password-wrapper ${login ? "" : "on-otp"}`}>
        <div className="left">
          <h3>
            {login
              ? "Please provide your email address to continue"
              : "Please enter the Verification Code sent to your email address"}
          </h3>
          {login ? null : (
            <div className="otp-content">
              <span style={{ fontSize: 12 }} className="spam-text">
                No email? Please check you spam folder and/or updates folder.
              </span>
              <div className="counter">
                {count !== 0 ? (
                  <Text type="secondary" style={{ fontSize: 12 }}>
                    {`Code expires in ${count}s`}
                  </Text>
                ) : (
                  <Button type="link" onClick={this.onSendOTP}>
                    Resend code
                  </Button>
                )}
              </div>
            </div>
          )}
          <div className="input-container">
            {login ? (
              <Input
                autoFocus
                name="email"
                placeholder="Email Address"
                onPressEnter={this.onSendOTP}
                onChange={(e) => {
                  let email = e.target.value;
                  onEmailChange(email);
                  this.setState({ email });
                }}
              />
            ) : (
              <Input
                autoFocus
                value={otp}
                placeholder="Enter Code"
                onPressEnter={this.onVerify}
                onChange={(e) => {
                  this.setState({ otp: e.target.value });
                }}
              />
            )}
          </div>
          <div className="button-container">
            {login ? (
              <Button
                style={{ width: "100%" }}
                disabled={email ? false : true}
                type="primary"
                shape="round"
                onClick={this.onSendOTP}
              >
                Get Verification Code
              </Button>
            ) : (
              <Button
                style={{ width: "100%" }}
                loading={loading}
                disabled={otp ? false : true}
                type="primary"
                shape="round"
                onClick={this.onVerify}
              >
                Login
              </Button>
            )}
          </div>
        </div>
      </div>
    );
  }
}
