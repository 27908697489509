import { Modal } from "antd";

export const checkDownloadPermissions = (resetDownload, onSave) => {
  Modal.confirm({
    title: "Warning",
    content: "You are about to allow users to download unprotected files.",
    width: "560px",
    centered: true,
    okText: "Allow download",
    cancelText: "Continue editing",
    onCancel: () => {
      //resetDownload();
    },
    onOk: () => {
      onSave();
    },
  });
};
