import React from "react";
import videojs from "video.js";
import "./index.scss";

/*
 * renders all the video files
 * Works using videojs
 */

export default class VideoPlayer extends React.Component {
  componentDidMount() {
    // instantiate Video.js
    this.player = videojs(
      this.videoNode,
      { ...this.props, playbackRates: [0.5, 1, 1.5, 2] },
      this.addErrorEvent
    );
  }

  addErrorEvent = () => {
    const { onError, storedTime, autoplay } = this.props;
    var player = this.player;
    player.ready(function () {
      if (storedTime > 0) {
        player.currentTime(storedTime);
      }
      if (autoplay) {
        player.play();
      }
    });
    player.on("error", function () {
      if (player.error().code === 2) {
        onError(player.currentTime());
      }
    });
  };

  // destroy player on unmount
  componentWillUnmount() {
    if (this.player) {
      this.player.dispose();
    }
  }

  // wrap the player in a div with a `data-vjs-player` attribute
  // so videojs won't create additional wrapper in the DOM
  // see https://github.com/videojs/video.js/pull/3856
  render() {
    return (
      <div data-vjs-player>
        <video
          style={{ width: "100%", height: "100%", background: "#F3F8FF" }}
          ref={(node) => (this.videoNode = node)}
          className="video-js"
          data-setup="{}"
          controls
          preload="none"
        ></video>
      </div>
    );
  }
}
