import React, { Component } from "react";
import "./App.css";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import ShareSettings from "./Components/ShareSettings";
import Layout from "./Layout";
import Monitoring from "./Components/Monitoring";
import OperationLoading from "./Components/OperationLoading";
import LocationLoader from "./Components/LocationLoader";
import Error from "./Components/Error";
import Provider from "./Context/MainContext";
import Callback from "./Components/Auth/Callback";
import TabViewer from "./Components/TabViewer";
import FileWrapper from "./Components/FileWrapper";

class App extends Component {
  render() {
    return (
      <Provider>
        <BrowserRouter>
          <Layout>
            <Switch>
              <Route
                path="/api/egnyte/service/file-share/:id"
                exact
                component={ShareSettings}
              />
              <Route
                path="/api/egnyte/service/open/document/:id"
                exact
                component={FileWrapper}
              />
              {/* <Route path="/shared" exact component={SharedFolders} /> */}
              <Route
                path="/api/egnyte/service/web_editor/d/:id"
                exact
                component={FileWrapper}
              />
              <Route
                path="/api/beacon/service/monitor/d/:id"
                exact
                component={Monitoring}
              />
              <Route
                path="/api/egnyte/service/:type/d/:id"
                exact
                component={OperationLoading}
              />
              <Route
                path="/api/egnyte/service/download/:id"
                exact
                component={OperationLoading}
              />
              <Route path="/api/egnyte/tabviewer" exact component={TabViewer} />

              <Route path="/location" exact component={LocationLoader} />
              <Route path="/error" exact component={Error} />
              <Route path="/callback" exact component={Callback} />
            </Switch>
          </Layout>
        </BrowserRouter>
      </Provider>
    );
  }
}

export default App;
