import moment from "moment";

export const formatBytes = (bytes, decimals = 2) => {
  if (bytes === 0) return "0 Bytes";

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
};

export const canBeOpened = (ext) => {
  const extensionList = [
    "pdf",
    "jpg",
    "jpeg",
    "jpe",
    "jif",
    "jfif",
    "jfi",
    "png",
    "gif",
    "webp",
    "mp4",
    "mov",
    "webm",
    "ogv",
    "avi",
    "flv",
    "ogg",
    "wmv",
    "mkv",
    "pptx",
    "ppt",
    "odp",
    "fodp",
    "otp",
    "pot",
    "potm",
    "potx",
    "pps",
    "ppsm",
    "ppsx",
    "pptm",
    "xlsx",
    "xls",
    "ods",
    "csv",
    "fods",
    "ots",
    "xlsm",
    "xlt",
    "xltm",
    "xltx",
    "docx",
    "doc",
    "odt",
    "txt",
    "docm",
    "dot",
    "dotm",
    "dotx",
    "epub",
    "fodt",
    "htm",
    "html",
    "mht",
    "ott",
    "pdf",
    "rtf",
    "djvu",
    "xps",
  ];

  return extensionList.includes(ext);
};

export const addEvents = () => {
  const macPlatform = ["Mac", "MacIntel", "Macintosh", "MacPPC", "Mac68K"];

  document.addEventListener(
    "contextmenu",
    function (e) {
      e.preventDefault();
    },
    false
  );

  document.addEventListener(
    "keydown",
    function (e) {
      //document.onkeydown = function(e) {
      // "I" key
      if (e.ctrlKey && e.shiftKey && e.keyCode === 73) {
        disabledEvent(e);
      }
      // "J" key
      if (e.ctrlKey && e.shiftKey && e.keyCode === 74) {
        disabledEvent(e);
      }
      // "S" key + macOS
      if (
        e.keyCode === 83 &&
        (macPlatform.includes(navigator.platform) ? e.metaKey : e.ctrlKey)
      ) {
        disabledEvent(e);
      }
      // "U" key
      if (e.ctrlKey && e.keyCode === 85) {
        disabledEvent(e);
      }
      // "F12" key
      if (e.keyCode === 123) {
        disabledEvent(e);
      }
    },
    false
  );
  function disabledEvent(e) {
    if (e.stopPropagation) {
      e.stopPropagation();
    } else if (window.event) {
      window.event.cancelBubble = true;
    }
    e.preventDefault();
    return false;
  }
};

export const getLocation = async () => {
  const getCoordinates = () => {
    return new Promise(function (resolve, reject) {
      navigator.geolocation.getCurrentPosition(resolve, reject);
      document.addEventListener("location-timeout", () => {
        reject();
      });
    });
  };
  if (navigator.geolocation) {
    setTimeout(() => {
      document.dispatchEvent(new Event("location-timeout"));
    }, 5000);
    return await getCoordinates();
  } else {
    return { coords: null };
  }
};

export const validateEmail = (email) => {
  const regex = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return regex.test(email);
};

export const firstLetterCaps = (params) => {
  if (typeof params === "string") {
    return params.charAt(0).toUpperCase() + params.slice(1);
  }
  return null;
};

export const formatFileName = (name) => {
  const isLong = name.length > 30;
  return isLong
    ? `${name.slice(0, 13)}...${name.slice(name.length - 13, name.length)}`
    : name;
};

export const getRelativeTime = (time) => {
  return moment(time).fromNow();
};

export const parseLocation = (location) => {
  if (typeof location === "string") {
    return parseFloat(location);
  }

  return location;
};

export const removeXSS = (text) => {
  const regex = /(\b)(on\S+)(\s*)=|javascript|(<\s*)(\/*)script/gi;

  while (regex.test(text)) {
    text = text.replace(regex, "");
  }

  // to remove all events
  text = text.replace(/ onw+="[^"]*"/g, "");

  return text;
};

export const renderTabIcon = (ext) => {
  let baseColor = getFavColor(ext);
  let favIcon = document.getElementById("favicon");
  favIcon.href = getSVGtoString(baseColor);
};

const getSVGtoString = (color) => {
  return `data:image/svg+xml;charset=UTF-8,%3Csvg width='512' height='512' viewBox='0 0 512 512' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M415.008 300.981C400.373 322.809 381.558 341.401 359.692 355.644C337.826 369.886 313.361 379.485 287.763 383.864V317.01C310.716 311.016 331.597 298.684 348.105 281.374C364.613 264.064 376.105 242.449 381.315 218.912C386.526 195.375 385.252 170.83 377.633 147.983C370.015 125.137 356.348 104.876 338.14 89.4346C323.51 76.9154 306.287 67.9284 287.763 63.1462C266.654 57.6179 244.514 57.6179 223.405 63.1462C204.877 67.9186 187.653 76.9067 173.028 89.4346C154.834 104.884 141.181 125.145 133.572 147.988C125.962 170.831 124.692 195.37 129.901 218.901C135.11 242.432 146.596 264.042 163.094 281.353C179.592 298.663 200.462 311.002 223.405 317.01V383.864C197.801 379.475 173.33 369.863 151.464 355.606C129.598 341.348 110.787 322.739 96.1599 300.895L47 343.641C67.6708 372.713 93.9876 397.15 124.318 415.438C154.649 433.725 188.347 445.471 223.321 449.948C244.69 452.684 266.31 452.684 287.679 449.948C322.656 445.481 356.357 433.739 386.689 415.451C417.021 397.163 443.336 372.72 464 343.641L415.008 300.981ZM255.479 255.628C242.74 255.628 230.288 251.782 219.696 244.575C209.105 237.368 200.851 227.124 195.978 215.14C191.105 203.156 189.832 189.97 192.32 177.249C194.809 164.528 200.946 152.845 209.957 143.676C218.967 134.507 230.446 128.266 242.941 125.74C255.436 123.215 268.386 124.52 280.152 129.489C291.919 134.459 301.974 142.87 309.045 153.659C316.117 164.447 319.887 177.129 319.878 190.1C319.867 207.468 313.089 224.122 301.031 236.408C288.973 248.693 272.621 255.606 255.563 255.628H255.479Z' 
fill='%23${color}'/%3E%3C/svg%3E%0A`;
};

const getFavColor = (ext) => {
  switch (ext) {
    case "pdf":
      return "FF6989";
    case "jpg":
    case "jpeg":
    case "jpe":
    case "jif":
    case "jfif":
    case "jfi":
    case "png":
    case "gif":
    case "webp":
      return "c3e5ff";
    case "mp4":
    case "mov":
    case "webm":
    case "ogv":
    case "avi":
    case "flv":
    case "ogg":
    case "wmv":
    case "mkv":
      return "7864FF";
    case "docx":
    case "doc":
    case "odt":
    case "txt":
    case "docm":
    case "dot":
    case "dotm":
    case "dotx":
    case "epub":
    case "fodt":
    case "htm":
    case "html":
    case "mht":
    case "ott":
    case "pdf":
    case "rtf":
    case "djvu":
    case "xps":
      return "1565c0";
    case "pptx":
    case "ppt":
    case "odp":
    case "fodp":
    case "otp":
    case "pot":
    case "potm":
    case "potx":
    case "pps":
    case "ppsm":
    case "ppsx":
    case "pptm":
      return "FF9A01";
    case "xlsx":
    case "xls":
    case "ods":
    case "csv":
    case "fods":
    case "ots":
    case "xlsm":
    case "xlt":
    case "xltm":
    case "xltx":
      return "27AE60";
    default:
      return "2F80ED";
  }
};
