import { pollVideoFile } from "../../Api/requests";

/*
 * This utils have all the major business loGic associated with folder viewer
 */

//Handle the response and switch based on the fileType
export const handleApiRes = (
  isFolder,
  res,
  setData,
  itemId,
  paths,
  setPaths,
  setLoading,
  setRes,
  fileName,
  setMode
) => {
  if (isFolder) {
    const { name, folderId } = res;
    setData(res);
    if (itemId) {
      if (!paths.some((item) => item.id === folderId)) {
        setPaths((prev) => [...prev, { title: name, id: folderId }]);
      }
    }
    setLoading(false);
  } else {
    const { fileType } = res;
    let payload = {};

    setRes({ ...res, itemId });
    switch (fileType) {
      case "DOCUMENT":
        setLoading(false);
        payload = { ...res, fileName, isOwner: false, itemId };
        openNewTab(payload);

        break;
      case "IMAGE":
      case "VIDEO":
        const { fileUrl, pollId } = res;

        if (fileUrl) {
          setLoading(false);
          payload = { ...res, fileName };
          openNewTab(payload);
        } else {
          setTimeout(() => {
            pollVideo({ pollId, setMode, setLoading, res, setRes, fileName });
          }, 1000);
        }
        break;
      default:
        setLoading(false);

        break;
    }
  }
};

// Video files have to be polled until the backend returns the fileUrl

export const pollVideo = (props) => {
  const { pollId, setMode, setLoading, setRes, res, fileName } = props;
  if (pollId) {
    pollVideoFile(pollId).then((pollData) => {
      if (pollData) {
        const { fileUrl } = pollData;
        if (fileUrl) {
          const payload = { ...res, fileUrl, pollId, fileName };

          setLoading(false);
          openNewTab(payload);
        } else {
          setTimeout(() => {
            pollVideo(props);
          }, 1000);
        }
      }
    });
  }
};

//For opening the file in a new tab and also to manage cross tab communication

const openNewTab = (payload) => {
  const url = `${window.location.origin}/api/egnyte/tabviewer`;
  window.open(url, `_blank`);
  const postData = { type: "anchor-file", payload };

  window.onmessage = (e) => {
    const { data, origin } = e;
    if (window.location.origin === origin) {
      if (data?.type === "anchor") {
        e.source.postMessage(postData, e.origin);
      }
    }
  };
};
