import React from "react";
import {
  Button,
  Checkbox,
  Input,
  message,
  Modal,
  Select,
  Space,
  Tag,
  Tooltip,
} from "antd";
import { removeXSS, validateEmail } from "../../../Utils";

export const RenderPermission = ({
  activeSharingOption,
  notifyUsers,
  userList,
  password,
  hasPassword,
  hasPasswordCopy,
  resetPassword,
  setState,
  isDownloadable,
  isFolder,
  downloadEnabled,
}) => {
  switch (activeSharingOption) {
    case "PUBLIC_ACCESS":
      return (
        <Space direction="vertical">
          <span className="premission-desc">
            Anyone with this link on the internet can access this file
          </span>
        </Space>
      );
    case "RESTRICTED_PASSWORD_ACCESS":
      return (
        <Space direction="vertical" className="width-100">
          <span className="premission-desc">
            Anyone with the password specified below can access this file
          </span>
          {hasPasswordCopy && hasPassword && (
            <div
              style={{
                marginLeft: 5,
                width: "100%",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <span>
                There is already a password associated with this file.
              </span>
              <Button
                onClick={() => {
                  setState({ resetPassword: !resetPassword });
                }}
              >
                {resetPassword ? "Cancel" : "Reset password"}
              </Button>
            </div>
          )}
          {!hasPasswordCopy && hasPassword && (
            <Input.Password
              value={password}
              autoComplete="new-password"
              key="egnyte-password"
              placeholder="Enter a password"
              onChange={(e) => {
                setState({ password: e.target.value });
              }}
            />
          )}
          {resetPassword && (
            <Input.Password
              value={password}
              autoComplete="new-password"
              key="egnyte-password"
              placeholder="Enter a new password"
              onChange={(e) => {
                setState({ password: e.target.value });
              }}
            />
          )}
        </Space>
      );
    case "RESTRICTED_EMAIL_ACCESS":
      const users = userList === "" ? [] : userList.split(",");
      return (
        <Space direction="vertical" className="width-100" size={12}>
          <div className="premission-desc">
            Only people added below can access this file.
          </div>
          <div className="premission-desc">
            <Tag color="blue">Note :</Tag>
            By default, the link owner can access the restricted access links.
          </div>
          <Select
            placeholder="You can paste multiple comma separated user email addresses"
            mode="tags"
            value={users}
            tokenSeparators={[","]}
            style={{ width: "100%" }}
            allowClear
            onChange={(values) => {
              onSelectChange(values, setState);
            }}
          ></Select>
          <div className="notify-block">
            <Checkbox
              checked={notifyUsers}
              onChange={(e) => {
                setState({ notifyUsers: e.target.checked });
              }}
            >
              Notify the above users with an email
            </Checkbox>
          </div>
          {isFolder && (
            <div className="notify-block">
              {isDownloadable ? (
                <Checkbox
                  checked={downloadEnabled}
                  onChange={(e) => {
                    setState({ downloadEnabled: e.target.checked });
                  }}
                >
                  Allow unprotected download
                </Checkbox>
              ) : (
                <Tooltip
                  placement="left"
                  trigger="hover"
                  title="Either this folder conatains more than 50 files or this feature is not enabled for your organization."
                >
                  <Checkbox disabled={!isDownloadable}>
                    Allow unprotected download
                  </Checkbox>
                </Tooltip>
              )}
            </div>
          )}
        </Space>
      );
    default:
      return null;
  }
};

export const onSelectChange = (values, setState) => {
  let errors = [],
    emails = [];

  values.forEach((i) => {
    const item = removeXSS(i);
    if (validateEmail(item)) {
      emails.push(item);
    } else {
      errors.push(item);
    }
  });
  let userList = [...new Set(emails)].join(",");
  setState({
    userList,
  });
  renderError(errors.filter((item) => item !== ""));
};

const renderError = (errors) => {
  if (errors.length !== 0) {
    Modal.confirm({
      title: "Incorrectly formatted email(s) found",
      content: (
        <div>
          <span>
            The below mentioned emails are not correctly formatted. These will
            be discarded.
          </span>
          <ul style={{ paddingTop: 5 }}>
            {errors.map((item) => (
              <li key={item}>{item}</li>
            ))}
          </ul>
        </div>
      ),
      cancelText: "Copy these",
      onCancel: () => {
        copyToClipboard(errors.join(","));
      },
    });
  }
};

const copyToClipboard = (str) => {
  const el = document.createElement("textarea");
  el.value = str;
  document.body.appendChild(el);
  el.select();
  document.execCommand("copy");
  document.body.removeChild(el);
  message.success("Copied to clipboard");
};
