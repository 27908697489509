import React, { Component } from "react";
import "./index.scss";
import { UnlockOutlined } from "@ant-design/icons";
import { Space } from "antd";
import MonitoringTable from "./MonitoringTable";
import MonitoringMap from "./MonitoringMap";
import { getMonitoringLogs } from "../../Api/requests";
import Spinner from "../Spinner";
import { Consumer } from "../../Context/MainContext";

export default (props) => (
  <Consumer>{(values) => <Index {...props} {...values} />}</Consumer>
);

export class Index extends Component {
  constructor(props) {
    super(props);

    this.state = {
      id: null,
      data: [],
      fileName: "",
      loading: true,
    };
  }

  componentDidMount = () => {
    const { match, setTitle } = this.props;
    let id = null;
    if (match.params) {
      id = match.params.id;
      this.setState({ id });
      this.fetchData(id);
      setTitle("File access logs");
    }
  };

  fetchData = (id) => {
    getMonitoringLogs(id).then((res) => {
      this.setState({ ...res, loading: false });
    });
  };

  render() {
    const { fileName, data, loading } = this.state;
    const newData = data.reverse();
    return (
      <div className="monitoring-home">
        <div className="container">
          {loading ? (
            <Spinner />
          ) : (
            <>
              <div className="left">
                <div className="accuracy">
                  <div className="filename">
                    <Space align="center">
                      <UnlockOutlined style={{ fontSize: 20 }} />
                      <span className="title">File Access Logs</span>
                    </Space>
                    <span className="name">{fileName}</span>
                  </div>
                  <span>*Accurate upto 100 meters</span>
                </div>
                <MonitoringTable data={newData} />
              </div>
              <div className="right">
                <MonitoringMap
                  data={newData}
                  googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyAUmSQ9SyItpTblUT9zT5qOewsh2oRnfJ0&callback=initMap&libraries=places"
                  loadingElement={<div style={{ height: `100%` }} />}
                  containerElement={<div className="map-container" />}
                  mapElement={<div style={{ height: `100%` }} />}
                />
              </div>
            </>
          )}
        </div>
      </div>
    );
  }
}
