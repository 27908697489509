import React, { useEffect, useState } from "react";
import CrownedCard from "../../Layout/CrownedCard";
import { renderViewer } from "../FileViewers/DocViewer";
import Spinner from "../Spinner";
import ImageViewer from "../FileViewers/ImageViewer";
import VideoPlayer from "../FileViewers/VideoPlayer";
import { pollVideoFile } from "../../Api/requests";
import { Consumer } from "../../Context/MainContext";
import { renderTabIcon } from "../../Utils";

const thisOrigin = window.location.origin;

export default (props) => (
  <Consumer>{(values) => <Index {...props} {...values} />}</Consumer>
);

const Index = ({ setTitle }) => {
  const [mode, setMode] = useState("init");
  const [fileData, setData] = useState(null);
  const [storedTime, setStoredTime] = useState(0);

  useEffect(() => {
    const prevData = sessionStorage.getItem("payload");
    //if data exists, then use it
    if (prevData) {
      const payload = JSON.parse(prevData);
      setPayload(payload);
    } else {
      //send a message to parent that page is loaded here
      window.parent.opener.postMessage(
        { type: "anchor", message: "loaded" },
        thisOrigin
      );
      // listen for messages from the parent.
      window.onmessage = (e) => {
        const { data, origin } = e;
        if (thisOrigin === origin) {
          if (data.type === "anchor-file") {
            const { payload } = data;
            setPayload(payload);
            //save data to sessionstorage incase of retaining data on refresh
            sessionStorage.setItem("payload", JSON.stringify(payload));
          }
        }
      };
    }
  }, []);

  const setPayload = (payload) => {
    setData(payload);
    setTitle(payload.fileName);
    renderTabIcon(payload.extension);
    setMode(payload.fileType);
  };

  const pollVideo = (props) => {
    const { pollId } = props;
    if (pollId) {
      pollVideoFile(pollId).then((pollData) => {
        if (pollData) {
          const { fileUrl } = pollData;
          if (fileUrl) {
            setData({ ...fileData, fileUrl, pollId });
          } else {
            setTimeout(() => {
              pollVideo(props);
            }, 1000);
          }
        }
      });
    }
  };
  const onTimeout = (storedTime) => {
    setStoredTime(storedTime);
    pollVideo({ pollId: fileData.pollId });
  };

  const renderMode = () => {
    const fileUrl = fileData?.fileUrl;

    switch (mode) {
      case "init":
        return (
          <CrownedCard>
            <Spinner />
          </CrownedCard>
        );

      case "DOCUMENT":
        renderViewer({ ...fileData });
        return null;

      case "IMAGE":
        return <ImageViewer fileUrl={fileUrl} />;

      case "VIDEO":
        const videoJsOptions = {
          autoplay: false,
          controls: true,
          sources: [
            {
              src: fileUrl,
              type: "video/mp4",
            },
          ],
          errorDisplay: true,
          onError: onTimeout,
          storedTime,
        };

        return (
          <div className="player-main" key={fileUrl}>
            <VideoPlayer {...videoJsOptions} />
          </div>
        );
      case "timeout":
        return (
          <CrownedCard>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flex: 1,
                fontSize: 18,
              }}
            >
              <span>Please reopen the file from the previous window</span>
            </div>
          </CrownedCard>
        );

      default:
        return <div></div>;
    }
  };

  return renderMode();
};
