export const termsItems = [
  "You are about to access a site/material that are strictly confidential and privileged to ACWA Power (“Restricted Materials”). Access is restricted to individuals who have been cleared by explicit authorization.",
  "You have been granted access to the Restricted Materials for a specific purpose that have been indicated in the clearance process. You agree and acknowledge that you will not use the Restricted Materials for any other purpose than the authorized purpose.",
  "Copying and/or reproducing the Restricted Materials or any part of it through any possible medium (electronic, digital or otherwise) is strictly prohibited unless you have explicit authorization to do so.",
  "You are solely responsible for maintaining the confidentiality of the RestrictedMaterials and shall not lend to any other person to use your access privileges without prior authorization.",
  "You will not use or reveal the Restricted Materials or any part of it or any information identified through this access either by yourself or others except in instances where you need to satisfy the authorized purpose.",
  "You will not make any disclosures or publications whereby any Restricted Materials information could be directly or indirectly identified or of an indicative nature that it can be traced to a specific project or individual in or outside ACWA Power and its group.",
  "You will not distribute the Restricted Materials or make it accessible or available to any third party, whether via electronic, physical medium or otherwise.",
  "You shall notify any of the Authorized Persons immediately upon your knowledge of any security breach associated with your access privileges.",
  "To the extent you feel legally obligated to disclose any information related to the Restricted Materials to any other person as a result any legal, investigatory or other demand for disclosure, you shall immediately consult with ACWA Power’s legal organization prior to making any disclosures.",
  "You shall agree and confirm that any violations or breaches on your part of the above shall subject you to serious legal and/or disciplinary actions and proceedings that ACWA Power shall institute against you that may include claims, losses, damages, costs, expenses, actions and other proceedings made, sustained, brought, prosecuted, threatened to be brought or prosecuted.",
];
