import React from "react";
import email from "../../../Res/email.png";
import "./index.scss";

const EmailSent = () => {
  return (
    <div className="email-container">
      <img src={email} />
      <h3>
        We are processing your download. You'll recieve an email shortly with
        the download link.
      </h3>
    </div>
  );
};

export default EmailSent;
