import axios from "axios";
import ServerDown from "../Components/Error/ServerDown";
import auth0Client from "./Auth0";

const baseURL = window.env.REACT_APP_BASE;

const base = (options) => {
  const token = auth0Client.getIdToken();
  return axios({
    baseURL,
    headers: {
      Accept: "application/json",
      Authorization: token ? `Bearer ${token}` : null,
    },
    ...options,
  })
    .then((res) => res.data)
    .catch(handleError);
};

export const get = (url, params) => {
  const options = {
    method: "get",
    url,
    params,
  };
  return base(options);
};

export const post = (url, data) => {
  const options = {
    method: "post",
    url,
    data,
  };
  return base(options);
};

export const del = (url) => {
  const options = {
    method: "delete",
    url,
  };
  return base(options);
};

const handleError = (error) => {
  console.log("Error", { error });
  if (error.response) {
    // The request was made and the server responded with a status code
    // that falls out of the range of 2xx
    if (error.response.status === 500) {
      ServerDown();
    }
    const { data } = error.response;

    //The condition check is for invalid passwords to be handled locally
    if (data.code !== "E40351") {
      //set error details to session storage
      sessionStorage.setItem("da-error", JSON.stringify(data));
      window.location.href = `/error`;
    }

    throw data;
  } else if (error.request) {
    // The request was made but no response was received
    // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
    // http.ClientRequest in node.js
    //ServerDown();
  } else {
    // Something happened in setting up the request that triggered an Error
    console.log("Error", error);
  }
};
