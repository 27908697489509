import React from "react";
import { Spin } from "antd";

export default ({ tip }) => {
  return (
    <div
      style={{
        display: "flex",
        flex: 1,
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
      }}
    >
      <Spin
        size="large"
        tip={tip || "This may take a few seconds... Please wait..."}
      />
    </div>
  );
};
