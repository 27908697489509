import { Progress } from "antd";
import React from "react";
import warning from "../../Res/error-triangle.svg";

const ProgressInfo = ({ status, percent }) => {
  const renderProgress = () => {
    switch (status) {
      case "warning":
        return <img src={warning} height="112px" width="112px" />;

      default:
        return <Progress type="circle" percent={percent} status={status} />;
    }
  };

  const renderText = () => {
    switch (status) {
      case "success":
        return "Successfully completed the operation.";
      case "warning":
        return "Operation failed with some of the files.";
      case "exception":
        return "Operation failed with all the files.";
      default:
        return "Your file operation is in progress";
    }
  };

  return (
    <>
      {renderProgress()}
      <div style={{ marginTop: 8, textAlign: "center" }}>
        <b>{renderText()}</b>
        {status === "active" && (
          <p>
            <small>
              This usually takes a few seconds to complete and can go up to a
              few minutes.
            </small>
          </p>
        )}
      </div>
    </>
  );
};

export default ProgressInfo;
