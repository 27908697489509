import React from "react";
import { Helmet } from "react-helmet";
import { Consumer } from "../Context/MainContext";
import logo from "../Res/logo_text.svg";

export default (props) => (
  <Consumer>{(values) => <Index {...props} {...values} />}</Consumer>
);

export const Index = ({ children, tabTitle }) => {
  const heading = `Anchor ${tabTitle ? "| " + tabTitle : ""}`;
  return (
    <>
      <Helmet>
        <title>{heading}</title>
      </Helmet>
      <div className="header">
        <div className="logo-container">
          <img src={logo} alt="logo" width="160px" />
        </div>
      </div>
      {children}
    </>
  );
};
