import React from "react";
import image from "../../../Res/fileLogos/image.svg";
import video from "../../../Res/fileLogos/video.svg";
import excel from "../../../Res/fileLogos/excel.svg";
import powerpoint from "../../../Res/fileLogos/powerpoint.svg";
import word from "../../../Res/fileLogos/word.svg";
import pdf from "../../../Res/fileLogos/pdf.svg";
import text from "../../../Res/fileLogos/text.svg";
import folder from "../../../Res/fileLogos/folder.svg";
import file from "../../../Res/fileLogos/file.svg";

export default (isFolder, ext) => {
  const images = {
    jpg: { id: "jpg", src: image },
    mp4: { id: "mp4", src: video },
    xls: { id: "xls", src: excel },
    ppt: { id: "ppt", src: powerpoint },
    doc: { id: "doc", src: word },
    pdf: { id: "pdf", src: pdf },
    txt: { id: "txt", src: text },
    folder: { id: "folder", src: folder },
    file: { id: "file", src: file },
  };
  if (isFolder) {
    return <img src={images["folder"].src} alt="icon" />;
  } else {
    switch (ext) {
      case "pdf":
        return <img src={images["pdf"].src} alt="icon" />;
      case "jpg":
      case "jpeg":
      case "jpe":
      case "jif":
      case "jfif":
      case "jfi":
      case "png":
      case "gif":
      case "webp":
        return <img src={images["jpg"].src} alt="icon" />;
      case "mp4":
      case "mov":
      case "webm":
      case "ogv":
      case "avi":
      case "flv":
      case "ogg":
      case "wmv":
      case "mkv":
        return <img src={images["mp4"].src} alt="icon" />;
      case "docx":
      case "doc":
      case "odt":
      case "txt":
      case "docm":
      case "dot":
      case "dotm":
      case "dotx":
      case "epub":
      case "fodt":
      case "htm":
      case "html":
      case "mht":
      case "ott":
      case "pdf":
      case "rtf":
      case "djvu":
      case "xps":
        return <img src={images["doc"].src} alt="icon" />;
      case "pptx":
      case "ppt":
      case "odp":
      case "fodp":
      case "otp":
      case "pot":
      case "potm":
      case "potx":
      case "pps":
      case "ppsm":
      case "ppsx":
      case "pptm":
        return <img src={images["ppt"].src} alt="icon" />;
      case "xlsx":
      case "xls":
      case "ods":
      case "csv":
      case "fods":
      case "ots":
      case "xlsm":
      case "xlt":
      case "xltm":
      case "xltx":
        return <img src={images["xls"].src} alt="icon" />;

      default:
        return <img src={images["file"].src} alt="icon" />;
    }
  }
};
