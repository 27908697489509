import { GlobalOutlined, LockOutlined, TeamOutlined } from "@ant-design/icons";
import { Select, Space } from "antd";
import React, { useEffect, useState } from "react";
import { permissions } from "./constants";
import "./index.scss";
import { RenderPermission } from "./utils";

const { Option } = Select;

export default (props) => {
  const { setState, sharingOptions, activeSharingOption } = props;

  useEffect(() => {
    changePermission(activeSharingOption);
  }, []);

  const changePermission = (key) => {
    switch (key) {
      case "PUBLIC_ACCESS":
        setState({
          hasPassword: false,
          authRequired: false,
          activeSharingOption: key,
        });
        break;
      case "RESTRICTED_PASSWORD_ACCESS":
        setState({
          hasPassword: true,
          authRequired: false,
          activeSharingOption: key,
        });
        break;
      case "RESTRICTED_EMAIL_ACCESS":
        setState({
          hasPassword: false,
          authRequired: true,
          activeSharingOption: key,
        });
        break;
      default:
        break;
    }
  };

  return (
    <Space
      direction="vertical"
      style={{ marginTop: 10, marginBottom: 10 }}
      className="width-100"
    >
      <Space direction="vertical" className="width-100">
        <label>Permissions</label>

        <Select
          className="width-100"
          value={activeSharingOption}
          onChange={changePermission}
        >
          {sharingOptions.map((item) => (
            <Option key={item}>
              <Space>
                {permissions[item].renderIcon()}
                <span>{permissions[item].title}</span>
              </Space>
            </Option>
          ))}
        </Select>
      </Space>
      <RenderPermission {...props} />
    </Space>
  );
};
