import auth0 from "auth0-js";

const { env } = window;

class Auth {
  constructor() {
    let url = window.location.href;
    let arr = url.split("/");
    let baseUrl = arr[0] + "//" + arr[2];
    let redirectUri = baseUrl + "/callback";
    this.auth0 = new auth0.WebAuth({
      domain: env.REACT_APP_AUTH0_DOMAIN,
      audience: env.REACT_APP_AUTH0_AUDIENCE,
      clientID: env.REACT_APP_AUTH0_CLIENTID,
      redirectUri: redirectUri,
      responseType: "id_token token",
      scope: "openid profile",
    });
    this.sendOtp = this.sendOtp.bind(this);
    this.verifyOtp = this.verifyOtp.bind(this);
    this.handleAuthentication = this.handleAuthentication.bind(this);
    this.isAuthenticated = this.isAuthenticated.bind(this);
  }

  getIdToken() {
    return sessionStorage.getItem("id_token"); //this.idToken;
  }

  isAuthenticated() {
    this.idToken = this.idToken
      ? this.idToken
      : sessionStorage.getItem("id_token");
    this.profile = this.profile
      ? this.profile
      : JSON.parse(sessionStorage.getItem("profile"));
    this.expiresAt = this.expiresAt
      ? this.expiresAt
      : parseInt(sessionStorage.getItem("expiry"));
    return new Date().getTime() < this.expiresAt;
  }

  sendOtp(email) {
    return new Promise((resolve, reject) => {
      this.auth0.passwordlessStart(
        {
          connection: "email",
          send: "code",
          email: email,
        },
        function (err, res) {
          if (err) {
            reject(err);
          } else {
            resolve(res);
          }
        }
      );
    });
  }

  verifyOtp(email, otp) {
    return new Promise((resolve, reject) => {
      this.auth0.passwordlessLogin(
        {
          connection: "email",
          email: email,
          verificationCode: otp,
        },
        function (err, res) {
          if (err) {
            reject(err);
          } else {
            resolve(res);
          }
        }
      );
    });
  }

  handleAuthentication() {
    return new Promise((resolve, reject) => {
      this.auth0.parseHash((err, authResult) => {
        if (err) {
          console.log(JSON.stringify(err));
          return reject(err);
        }
        if (!authResult || !authResult.idToken) {
          return reject(err);
        }
        sessionStorage.setItem("id_token", authResult.idToken);
        sessionStorage.setItem("expiry", authResult.idTokenPayload.exp * 1000);
        sessionStorage.setItem(
          "profile",
          JSON.stringify(authResult.idTokenPayload)
        );
        this.idToken = authResult.idToken;
        this.profile = authResult.idTokenPayload;
        this.expiresAt = authResult.idTokenPayload.exp * 1000;
        resolve();
      });
    });
  }
}

const auth0Client = new Auth();

export default auth0Client;
