import { Tooltip } from "antd";
import React from "react";
import {
  canBeOpened,
  formatBytes,
  formatFileName,
  getRelativeTime,
} from "../../../Utils";
import "./index.scss";
import renderIcon from "./renderIcon";

/*
 * This component renders each item displayed in the folder viewer
 */

export default ({
  name,
  isFolder,
  size,
  folderId,
  uploadedBy,
  lastModified,
  fileId,
  getData,
}) => {
  //get extension
  const parts = name.split(".");
  const ext = parts[parts.length - 1].toLowerCase();
  const active = canBeOpened(ext);

  //for opening files
  const onOpen = () => {
    if (active) {
      getData(fileId, isFolder, name);
    }
  };
  //for opening folders
  const openFolder = () => {
    if (isFolder) {
      getData(folderId, isFolder);
    }
  };

  return (
    <div
      className="shared-item-main"
      style={{ cursor: isFolder ? "pointer" : "default" }}
      onClick={openFolder}
    >
      <div className="icon-and-name">
        <div className={`${isFolder ? "folder-icon" : "item-icon"}`}>
          {renderIcon(isFolder, ext)}
        </div>
        <span className="item-name">
          <Tooltip title={name}>{formatFileName(name)}</Tooltip>
        </span>
      </div>
      <div className="item-details">
        <span className="item-size">
          {isFolder ? "---" : formatBytes(size)}
        </span>
        <span className="item-modified">{getRelativeTime(lastModified)}</span>
        <span className="item-by">{uploadedBy || ""}</span>
        {!isFolder && (
          <div
            onClick={onOpen}
            className={`open-button ${active ? "enabled" : "disabled"}-button`}
          >
            {active ? (
              "Open"
            ) : (
              <Tooltip title="This file is not supported">Open</Tooltip>
            )}
          </div>
        )}
      </div>
    </div>
  );
};
