import React, { Component } from "react";
import moment from "moment";
import "./index.scss";
import { Empty } from "antd";

export default class extends Component {
  getAction = (action) => {
    switch (action.toLowerCase()) {
      case "open file":
      case "open":
        return (
          <div className="item-container">
            <div className="actionItem open">{action}</div>
          </div>
        );
      case "new file":
      case "newfile":
      case "anchor":
        return (
          <div className="item-container">
            <div className="actionItem new">{action}</div>
          </div>
        );
      case "edit file":
      case "edited":
      case "edit":
        return (
          <div className="item-container">
            <div className="actionItem edit">{action}</div>
          </div>
        );
      case "revoke":
        return (
          <div className="item-container">
            <div className="actionItem revoke">{action}</div>
          </div>
        );
      case "deny":
        return (
          <div className="item-container">
            <div className="actionItem deny">{action}</div>
          </div>
        );
      case "user-block":
        return (
          <div className="item-container">
            <div className="actionItem user-block">{action}</div>
          </div>
        );
      case "un-anchor":
        return (
          <div className="item-container">
            <div className="actionItem un-anchor">{action}</div>
          </div>
        );
      case "add-protection":
        return (
          <div className="item-container">
            <div className="actionItem add-protection">{action}</div>
          </div>
        );
      case "remove-protection":
        return (
          <div className="item-container">
            <div className="actionItem remove-protection">{action}</div>
          </div>
        );
      case "email-attachment":
        return (
          <div className="item-container">
            <div className="actionItem email-attachment">{action}</div>
          </div>
        );
      case "download":
        return (
          <div className="item-container">
            <div className="actionItem email-attachment">{action}</div>
          </div>
        );
      default:
        return <div>{action}</div>;
    }
  };
  render() {
    const { data } = this.props;
    return (
      <div className="table-container">
        <table className="table">
          <thead>
            <tr className="table-header">
              <th style={{ width: 100 }} className="table-border-left">
                #
              </th>
              <th>User</th>
              <th>Action</th>
              <th className="table-border-right">Time</th>
            </tr>
          </thead>
          <tbody>
            {data.length > 0 ? (
              data.map((item, i) => (
                <tr>
                  <td style={{ width: 100 }}>{i + 1}</td>
                  <td style={{ wordBreak: "break-all" }}>{item.userName}</td>
                  <td>{this.getAction(item.action)}</td>
                  <td>
                    {moment(
                      item.lastAccessed,
                      "YYYY-MM-DDTHH:mm:ss.SSSSSSSSSZ"
                    ).fromNow()}
                  </td>
                </tr>
              ))
            ) : (
              <div style={{ marginTop: 10 }}>
                <Empty description="No file access logs found" />
              </div>
            )}
          </tbody>
        </table>
      </div>
    );
  }
}
