import React, { useEffect, useState } from "react";
import { Breadcrumb, Button, Divider, Empty, Input, Spin, Tooltip } from "antd";
import "./index.scss";
import DownloadModal from "../DownloadModal";

export default ({
  sharedBy,
  setSearch,
  totalCount,
  search,
  gotoPath,
  rootName,
  paths,
  id,
  folderId,
  isDownloadable,
  downloadEnabled,
}) => {
  const [download, setDownload] = useState(false);
  const [retryTimeout, setRetryTimeout] = useState(0);
  useEffect(() => {
    //check if a timer is already in progress (for window refresh scenarios)
    const time = sessionStorage?.getItem("download-retry");
    if (time && time > 0) {
      //if a prev timer exists, continue it.
      setRetryTimeout(time);
    }
    //adds an event listener for timer trigger.
    document.addEventListener("start-timer", () => {
      setRetryTimeout(60); //time our is set to 1 min by default
    });
  }, []);

  useEffect(() => {
    const intervalId = setInterval(() => {
      if (retryTimeout !== 0) {
        //reduces count each time and updates local and session state
        const newTime = retryTimeout - 1;
        sessionStorage.setItem("download-retry", newTime);
        setRetryTimeout(newTime);
      } else {
        clearInterval(intervalId);
      }
    }, 1000);
    return () => clearInterval(intervalId);
  }, [retryTimeout]);

  const onDownload = () => {
    setDownload(true);
  };

  const onClose = () => {
    setDownload(false);
  };
  return (
    <div className="header-main">
      <div className="shared-header">
        {download ? <DownloadModal {...{ id, folderId, onClose }} /> : null}
        <div className="folder-details">
          <span className="shared-by">
            Shared by:
            <span className="user-name">{sharedBy}</span>
          </span>
          <Divider type="vertical" />
          {retryTimeout === 0 ? (
            isDownloadable &&
            downloadEnabled && (
              <Button onClick={onDownload}>Download as zip</Button>
            )
          ) : (
            <Tooltip
              title={`Your previous download is in progress. You can try to initate another
            download in ${retryTimeout} seconds`}
            >
              <Button disabled>Retry in {retryTimeout} seconds</Button>
            </Tooltip>
          )}
        </div>
        <div className="folder-search">
          <Input.Search
            onChange={(e) => {
              setSearch(e.target.value);
            }}
            value={search}
            placeholder="Search files here.."
            style={{ maxWidth: 300, marginRight: 15, minWidth: 250 }}
          />
          <div className="total-files">
            Listed Files:
            <span className="file-count">{totalCount || 0} </span>
          </div>
        </div>
      </div>
      <div className="paths">
        {/* Location: */}
        <Breadcrumb separator=">">
          <Breadcrumb.Item
            onClick={() => {
              gotoPath(null);
            }}
          >
            {rootName}
          </Breadcrumb.Item>
          {paths.map((item, i) => (
            <Breadcrumb.Item
              key={i}
              onClick={() => {
                gotoPath(item.id, i);
              }}
            >
              {item.title}
            </Breadcrumb.Item>
          ))}
        </Breadcrumb>
      </div>
    </div>
  );
};
