import { Modal } from "antd";

export default () => {
  Modal.confirm({
    title: "Server down!",
    content:
      "Our services are down at this time. Please try again after sometime or contact our support",
    okText: "Refresh",
    cancelText: "Close",
    onOk: () => {
      window.location.reload();
    },
  });
};
