import React from "react";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import "./index.scss";

/*
 * renders all the image files
 */

const ControlButtons = ({ zoomIn, zoomOut, resetTransform }) => {
  return (
    <div className="preview-toolbar">
      <div className="preview-toolbar__hoverable-area">
        <div className="preview-toolbar__toolbox">
          <button
            onClick={zoomOut}
            data-tid="zoom-out"
            type="button"
            data-active="true"
            className="_1X6cYbjZhaW9vECvgLHDAx Mle_5kopkGHRz-tDWyKly"
          >
            <svg
              viewBox="0 0 24 24"
              role="presentation"
              style={{ width: "1em", height: "1em" }}
            >
              <path
                d="M15.5,14H14.71L14.43,13.73C15.41,12.59 16,11.11 16,9.5A6.5,6.5 0 0,0 9.5,3A6.5,6.5 0 0,0 3,9.5A6.5,6.5 0 0,0 9.5,16C11.11,16 12.59,15.41 13.73,14.43L14,14.71V15.5L19,20.5L20.5,19L15.5,14M9.5,14C7,14 5,12 5,9.5C5,7 7,5 9.5,5C12,5 14,7 14,9.5C14,12 12,14 9.5,14M7,9H12V10H7V9Z"
                style={{ fill: "currentcolor" }}
              ></path>
            </svg>
          </button>
          <button
            onClick={zoomIn}
            data-tid="zoom-in"
            type="button"
            data-active="true"
            className="_1X6cYbjZhaW9vECvgLHDAx Mle_5kopkGHRz-tDWyKly"
          >
            <svg
              viewBox="0 0 24 24"
              role="presentation"
              style={{ width: "1em", height: "1em" }}
            >
              <path
                d="M15.5,14L20.5,19L19,20.5L14,15.5V14.71L13.73,14.43C12.59,15.41 11.11,16 9.5,16A6.5,6.5 0 0,1 3,9.5A6.5,6.5 0 0,1 9.5,3A6.5,6.5 0 0,1 16,9.5C16,11.11 15.41,12.59 14.43,13.73L14.71,14H15.5M9.5,14C12,14 14,12 14,9.5C14,7 12,5 9.5,5C7,5 5,7 5,9.5C5,12 7,14 9.5,14M12,10H10V12H9V10H7V9H9V7H10V9H12V10Z"
                style={{ fill: "currentcolor" }}
              ></path>
            </svg>
          </button>
          <button
            onClick={resetTransform}
            data-tid="enter-fullscreen"
            type="button"
            data-active="true"
            className="_1X6cYbjZhaW9vECvgLHDAx Mle_5kopkGHRz-tDWyKly"
          >
            <svg
              viewBox="0 0 24 24"
              role="presentation"
              style={{ width: "1em", height: "1em" }}
            >
              <path
                d="M5,5H10V7H7V10H5V5M14,5H19V10H17V7H14V5M17,14H19V19H14V17H17V14M10,17V19H5V14H7V17H10Z"
                style={{ fill: "currentcolor" }}
              ></path>
            </svg>
          </button>
        </div>
      </div>
    </div>
  );
};

export default ({ fileUrl, style }) => {
  return (
    <div className="img-viewer" style={style}>
      <TransformWrapper>
        {(props) => (
          <>
            <ControlButtons {...props} />
            <TransformComponent>
              <img
                className="image-main"
                src={fileUrl}
                alt="img"
                loading="lazy"
              />
            </TransformComponent>
          </>
        )}
      </TransformWrapper>
    </div>
  );
};
