import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import auth0Client from "../../../Api/Auth0";

class Index extends Component {
  async componentDidMount() {
    try {
      await auth0Client.handleAuthentication();
      this.handleAfterLogin();
    } catch {
      this.props.history.push("/403");
    }
  }

  handleAfterLogin = () => {
    let redirectPath = localStorage.getItem("anchorRedirectPath") || "/";
    this.props.history.push(redirectPath);
    localStorage.removeItem("anchorRedirectPath");
  };

  render() {
    return <p>Loading...</p>;
  }
}

export default withRouter(Index);
