const map = {
  E40033: "Cannot encrypt a file that is already encrypted",
  E40030: "Cannot decrypt a file that is already decrypted",
};

export const getMessage = (error) => {
  const { message, status } = error;
  if (map.hasOwnProperty(status)) {
    return map[status];
  } else {
    return message;
  }
};
