import { get, post } from "./base";

const filePrefix = "api/v2/file/";
const folderPrefix = "api/v2/folder/";
const pollPrefix = "api/egnyte/service/file-progress/";

export const encryptFile = (id, data) => {
  return post(`${filePrefix}encrypt/${id}`, data);
};

export const decryptFile = (id, data) => {
  return post(`${filePrefix}decrypt/${id}`, data);
};

export const getOperationProgress = (id) => {
  return get(`${filePrefix}operation-progress/${id}`);
};

export const getFile = (id) => {
  return get(`${filePrefix}open/${id}`);
};
export const openFile = (id, data) => {
  return post(`${filePrefix}open/${id}`, data);
};

export const getSharedFile = (id) => {
  return get(`${filePrefix}share/${id}`);
};

export const getSharedFolder = (id, params) => {
  return get(`${folderPrefix}${id}`, params);
};

export const openSharedFile = (id, data) => {
  return post(`${filePrefix}share/${id}`, data);
};

export const pollVideoFile = (id) => {
  return get(`${pollPrefix + id}`);
};

export const getShareSettings = (id) => {
  return get(`${filePrefix}share-settings/${id}`);
};

export const setShareSettings = (id, data) => {
  return post(`${filePrefix}share-settings/${id}`, data);
};

export const getMonitoringLogs = (id) => {
  return get(`${filePrefix}monitor/${id}`);
};

export const triggerDownload = (id, folderId) => {
  return post(`${folderPrefix}download/${id}`, { folderId });
};
