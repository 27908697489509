import { Button, Input, Form, message } from "antd";
import React, { Component } from "react";
import CrownedCard from "../../Layout/CrownedCard";
import { addEvents, getLocation, validateEmail } from "../../Utils";
import {
  openSharedFile,
  pollVideoFile,
  openFile,
  getSharedFolder,
} from "../../Api/requests";
import Spinner from "../Spinner";
import md5 from "md5";
import { renderViewer } from "../FileViewers/DocViewer";
import "./index.scss";
import ImageViewer from "../FileViewers/ImageViewer";
import VideoPlayer from "../FileViewers/VideoPlayer";
import FolderViewer from "../FolderViewer";
import auth0Client from "../../Api/Auth0";
import OTPLogin from "../OTPLogin";
import { termsAndConditions } from "../Terms/constant";
import { Terms } from "../Terms";

/*
 * that decides which type of file to render
 */

const resetables = {
  fileUrl: null,
  pollId: null,
  storedTime: 0,
  passwordStatus: "success",
  errorText: "",
};

export default class extends Component {
  constructor(props) {
    super(props);

    this.state = {
      mode: "loading",
      id: null,
      password: null,
      email: null,
      location: null,
      fileType: null,
      initData: { folders: [], files: [] },
      ...resetables,
    };
  }

  componentDidMount() {
    const { fileType, id } = this.props;
    //store filetype and ID to state
    this.setState({ fileType, id });
    //get location from the browser
    getLocation()
      .then((data) => {
        this.setState({ location: data.coords }, this.checkOwnerShip);
      })
      .catch((err) => {
        this.checkOwnerShip();
      });

    addEvents();
  }

  //If it's an open with operation, directly call the open file api
  //If not check the params for auth required or hasPassword to render those checks
  checkOwnerShip = () => {
    const { isOwner, authRequired } = this.props;
    if (isOwner) {
      this.onSubmit();
    } else {
      //if authenticated proceed to next mode
      auth0Client.isAuthenticated() && authRequired
        ? this.authVerified()
        : this.setState({ mode: "init" });
    }
  };

  //Email inputs are handled and verified here and if there is a password associated, renders the password screen
  handleEmail = () => {
    const { email } = this.state;
    const { hasPassword } = this.props;
    if (email) {
      if (validateEmail(email)) {
        if (hasPassword) {
          this.setState({ mode: "password" });
        } else {
          this.checkOrgId();
        }
      } else {
        message.warning(`Please enter a valid email address`);
      }
    } else {
      message.warning("Please enter your email to continue");
    }
  };

  //For verified users by OTP that returns to this page by a callback handler, get the location again and proceed to submit
  authVerified = () => {
    //location cannot be stored to storage for security reasons, therefore recalculating
    getLocation()
      .then((data) => {
        const { latitude, longitude } = data.coords;
        this.setState({ location: { latitude, longitude } }, this.checkOrgId);
      })
      .catch((err) => {
        this.checkOrgId();
      });
  };

  validatePassword = () => {
    const { hasPassword, orgId } = this.props;
    const { password } = this.state;
    if (hasPassword) {
      if (password) {
        this.checkOrgId();
      } else {
        this.setState({
          mode: "password",
          passwordStatus: "error",
          errorText: "The password is empty",
        });
      }
    } else {
      this.checkOrgId();
    }
  };
  //checks if the org needs to render the terms and conditions
  checkOrgId = () => {
    const { orgId } = this.props;
    //split comma separated list to array
    const requiredOrgs =
      window?.env?.REACT_APP_ENABLE_TERMS_FOR_SHARING?.split(",");
    if (requiredOrgs.includes(orgId)) {
      this.setState({ mode: "terms" });
    } else {
      this.onSubmit();
    }
  };

  onSubmit = () => {
    const { hasPassword, fileName, isOwner, fileType } = this.props;

    //if authenticated, take state from the storage which was stored before callback reload
    const state = auth0Client.isAuthenticated()
      ? JSON.parse(sessionStorage.getItem("anchorState"))
      : this.state;
    const { email } = state;

    //cannot be stored to storage

    const { password, location, id } = this.state;

    let latitude = null,
      longitude = null;

    if (location) {
      latitude = location.latitude;
      longitude = location.longitude;
    }

    const payload = {
      password: hasPassword ? md5(password) : null,
      email,
      latitude,
      longitude,
    };

    this.setState({ mode: "loading" });

    let api = openSharedFile;
    if (fileType === "FOLDER") {
      api = getSharedFolder;
    }
    if (isOwner) {
      api = openFile;
    }

    api(id, payload)
      .then((res) => {
        if (res) {
          switch (fileType) {
            case "DOCUMENT":
              renderViewer({ ...res, fileName, isOwner });
              break;
            case "IMAGE":
            case "VIDEO":
              const { fileUrl } = res;
              if (fileUrl) {
                this.setState({ fileUrl, mode: "media" });
              } else {
                setTimeout(() => {
                  this.pollVideo();
                }, 1000);
              }
              break;
            case "FOLDER":
              this.setState({ mode: "folder", initData: res });
          }
        }
      })
      .catch((error) => {
        const { code, description } = error;
        if (code === "E40351") {
          this.setState({
            mode: "password",
            passwordStatus: "error",
            errorText: description,
          });
        }
      });
  };

  pollVideo = () => {
    const { id } = this.state;
    if (id) {
      pollVideoFile(id).then((res) => {
        if (res) {
          const { fileUrl } = res;
          if (fileUrl) {
            this.setState({ fileUrl, mode: "media" });
          } else {
            setTimeout(() => {
              this.pollVideo();
            }, 1000);
          }
        }
      });
    }
  };

  onTimeout = (storedTime) => {
    this.setState({ storedTime });
    this.pollVideo();
  };

  onEmailChange = (email) => {
    this.setState({ email });
  };

  renderMode = () => {
    const {
      mode,
      password,
      fileUrl,
      fileType,
      storedTime,
      passwordStatus,
      errorText,
    } = this.state;
    const { hasPassword, authRequired, isDownloadable, downloadEnabled } =
      this.props;

    switch (mode) {
      case "init":
        return (
          <CrownedCard>
            <div className="wrapper-main">
              <>
                {authRequired ? (
                  <OTPLogin
                    onEmailChange={this.onEmailChange}
                    state={this.state}
                  />
                ) : (
                  <>
                    {hasPassword && (
                      <h2 style={{ marginTop: 10 }}>
                        Password protected content!
                      </h2>
                    )}
                    <Input
                      name="email"
                      autoFocus
                      placeholder="Please enter your email ID to access the content"
                      style={{ width: "80%" }}
                      onPressEnter={this.handleEmail}
                      onChange={(e) => this.setState({ email: e.target.value })}
                    />
                    <div style={{ textAlign: "center" }}>
                      <Button
                        type="primary"
                        className="rounded-button"
                        onClick={this.handleEmail}
                      >
                        Submit
                      </Button>
                    </div>
                  </>
                )}
              </>
            </div>
          </CrownedCard>
        );
      case "terms":
        return (
          <CrownedCard>
            <Terms onSubmit={this.onSubmit} />
          </CrownedCard>
        );
      case "password":
        return (
          <CrownedCard>
            <div className="wrapper-main">
              <h2 style={{ marginTop: 10 }}>Password protected content!</h2>
              <Form style={{ width: "80%", textAlign: "center" }}>
                <Form.Item validateStatus={passwordStatus} help={errorText}>
                  <Input.Password
                    value={password}
                    name="password"
                    placeholder="Please enter the password to access the content"
                    onChange={(e) =>
                      this.setState({ password: e.target.value })
                    }
                    onPressEnter={this.validatePassword}
                  />
                </Form.Item>
              </Form>
              <div style={{ textAlign: "center" }}>
                <Button
                  type="primary"
                  className="rounded-button"
                  onClick={this.validatePassword}
                >
                  Submit
                </Button>
              </div>
            </div>
          </CrownedCard>
        );
      case "loading":
        return (
          <CrownedCard>
            <Spinner />
          </CrownedCard>
        );

      case "media":
        if (fileType === "IMAGE") {
          return <ImageViewer fileUrl={fileUrl} />;
        } else {
          const videoJsOptions = {
            autoplay: false,
            controls: true,
            sources: [
              {
                src: fileUrl,
                type: "video/mp4",
              },
            ],
            errorDisplay: true,
            onError: this.onTimeout,
            storedTime,
          };
          console.log("bef", videoJsOptions);

          return (
            <div className="player-main" key={fileUrl}>
              <VideoPlayer {...videoJsOptions} />
            </div>
          );
        }

      case "folder":
        return (
          <FolderViewer
            {...this.state}
            {...{ isDownloadable, downloadEnabled }}
          />
        );

      default:
        break;
    }
  };

  render() {
    return <>{this.renderMode()}</>;
  }
}
