import React, { Component } from "react";
import "./index.scss";
import { CheckOutlined, CloseOutlined, StopOutlined } from "@ant-design/icons";
import anchor from "../../../Res/logo_mark.svg";
import errorIcon from "../../../Res/error.svg";
import warning from "../../../Res/warning.svg";
import { formatFileName } from "../../../Utils";
import { getMessage } from "../../Error/errorMapping";

export default class extends Component {
  constructor(props) {
    super(props);

    this.state = {
      percent: 0,
      encryption: 0,
      OID: null,
      mode: null,
      expanded: false,
    };
  }
  componentDidMount() {
    const { success } = this.props;
    let status = "";
    if (success) {
      status = "done";
    } else {
      const { error } = this.props;
      const skippedCodes = ["E40030", "E40033"];
      if (skippedCodes.includes(error.status)) {
        status = "skipped";
      } else {
        status = "error";
      }
    }

    this.setState({ mode: status });
  }

  renderMode = () => {
    const { mode, expanded } = this.state;
    const { encrypt, error } = this.props;
    switch (mode) {
      case "done":
        return (
          <div className="encrypted-anchor">
            <div className="anchor">
              <img src={anchor} alt="anchor" />
            </div>
            <div className="text">
              {encrypt ? "En" : "De"}crypted successfully
            </div>
          </div>
        );
      case "error":
        return (
          <div className="error-icon">
            <div className="icon-and-text">
              <div className="anchor">
                <img src={errorIcon} alt="anchor" style={{ height: "80%" }} />
              </div>
              <span className="error-text">Failed to process</span>
              <span
                className="more"
                onClick={() => {
                  this.setState({ expanded: !expanded });
                }}
              >{`Show ${expanded ? "less" : "more"}`}</span>
            </div>
            <div
              className={`error-details ${
                expanded ? "has-height" : ""
              } custom-scroll`}
            >
              <span>Code: {error?.status}</span>
              <span>Message: {getMessage(error)}</span>
            </div>
          </div>
        );
      case "skipped":
        return (
          <div className="error-icon">
            <div className="icon-and-text">
              <div className="warning">
                <img src={warning} alt="anchor" style={{ height: "80%" }} />
              </div>
              <span className="error-text">Skipped processing</span>
              <span
                className="more"
                onClick={() => {
                  this.setState({ expanded: !expanded });
                }}
              >{`Show ${expanded ? "less" : "more"}`}</span>
            </div>
            <div
              className={`error-details ${
                expanded ? "has-height" : ""
              } custom-scroll`}
            >
              <span>Code: {error?.status}</span>
              <span>Message: {getMessage(error)}</span>
            </div>
          </div>
        );
      default:
        break;
    }
  };

  renderIconMode = () => {
    const { mode } = this.state;
    switch (mode) {
      case "done":
        return <CheckOutlined style={{ color: "rgb(83, 201, 167)" }} />;
      case "error":
        return <CloseOutlined style={{ color: "#f15b41" }} />;
      case "skipped":
        return <StopOutlined style={{ color: "#f5bd1f" }} />;
      default:
        break;
    }
  };

  render() {
    const { path } = this.props;
    const parts = path.split("/"),
      name = parts[parts.length - 1];
    return (
      <div className={`progress-item`}>
        <div className="content">
          <div className="text">
            <div className="name">{formatFileName(name)}</div>
          </div>
          <div className="action">{this.renderIconMode()}</div>
        </div>
        {this.renderMode()}
      </div>
    );
  }
}
