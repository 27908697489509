import React from "react";
import { GlobalOutlined, LockOutlined, TeamOutlined } from "@ant-design/icons";

export const permissions = {
  PUBLIC_ACCESS: {
    title: "Anyone with the link",
    renderIcon: () => <GlobalOutlined style={{ color: "#1890ff" }} />,
  },
  RESTRICTED_PASSWORD_ACCESS: {
    title: "Anyone with a password",
    renderIcon: () => <LockOutlined style={{ color: "#1890ff" }} />,
  },
  RESTRICTED_EMAIL_ACCESS: {
    title: "Restricted access",
    renderIcon: () => <TeamOutlined style={{ color: "#1890ff" }} />,
  },
};
