import React from "react";

import ReactDOMServer from "react-dom/server";

export default ({ index }) => {
  return ReactDOMServer.renderToString(
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="109"
      height="115"
      viewBox="0 0 109 115"
    >
      <defs>
        <clipPath id="clip-path">
          <rect
            id="Rectangle_3"
            data-name="Rectangle 3"
            width="109"
            height="115"
            transform="translate(224 160)"
            fill="#fff"
          />
        </clipPath>
      </defs>
      <g
        id="Mask_Group_3"
        data-name="Mask Group 3"
        transform="translate(-224 -160)"
        clip-path="url(#clip-path)"
      >
        <g id="anchor" transform="translate(225.572 173.574)">
          <g id="Group_4" data-name="Group 4">
            <path
              id="Path_7"
              data-name="Path 7"
              d="M89.161,56.031l-6.6,11.43h4.056c-.534,8.2-7.526,14.692-17.607,17.98A7.526,7.526,0,0,1,59.7,80.969a33.913,33.913,0,0,1-2.52-12.2v-50.4H49.67v50.4a33.913,33.913,0,0,1-2.523,12.2,7.526,7.526,0,0,1-9.306,4.471c-10.082-3.286-17.073-9.782-17.607-17.98h4.059l-6.6-11.43L11.1,67.46h4.129C15.86,80.931,29.04,93.261,46.738,95.8l6.688,5.631L60.119,95.8C77.816,93.261,91,80.931,91.632,67.46H95.76Z"
              fill="#00b0fa"
            />
            <path
              id="Path_8"
              data-name="Path 8"
              d="M57.2,87.847a1.459,1.459,0,0,1-1.109-.509,13.976,13.976,0,0,1-2.411-4.007A1.46,1.46,0,1,1,56.4,82.264a11.052,11.052,0,0,0,1.91,3.172A1.46,1.46,0,0,1,57.2,87.848Z"
              fill="#00b0fa"
            />
            <g id="Group_3" data-name="Group 3" transform="translate(-13 -17)">
              <circle
                id="Ellipse_3"
                data-name="Ellipse 3"
                cx="20"
                cy="20"
                r="20"
                transform="translate(45.913 5.426)"
                fill="#00b0fa"
              />
            </g>
            <path
              id="Path_9"
              data-name="Path 9"
              d="M29.426,33.809h48v7.513h-48Z"
              fill="#00b0fa"
            />
          </g>
        </g>
        <text
          id="_25"
          data-name="25"
          transform="translate(266 167)"
          fill="#fff"
          font-size="20"
          font-family="SegoeUI, Segoe UI"
        >
          <tspan x="1.719" y="22">
            {index || "00"}
          </tspan>
        </text>
      </g>
    </svg>
  );
};

export const customMarkup = (index) => {
  return `data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' width='47' height='63' viewBox='0 0 109 115' %3e%3cdefs%3e%3cclipPath id='clip-path'%3e%3crect id='Rectangle_3' data-name='Rectangle 3' width='109' height='115' transform='translate(224 160)' fill='%23fff' /%3e%3c/clipPath%3e%3c/defs%3e%3cg id='Mask_Group_3' data-name='Mask Group 3' transform='translate(-224 -160)' clip-path='url(%23clip-path)' %3e%3cg id='anchor' transform='translate(225.572 173.574)'%3e%3cg id='Group_4' data-name='Group 4'%3e%3cpath id='Path_7' data-name='Path 7' d='M89.161,56.031l-6.6,11.43h4.056c-.534,8.2-7.526,14.692-17.607,17.98A7.526,7.526,0,0,1,59.7,80.969a33.913,33.913,0,0,1-2.52-12.2v-50.4H49.67v50.4a33.913,33.913,0,0,1-2.523,12.2,7.526,7.526,0,0,1-9.306,4.471c-10.082-3.286-17.073-9.782-17.607-17.98h4.059l-6.6-11.43L11.1,67.46h4.129C15.86,80.931,29.04,93.261,46.738,95.8l6.688,5.631L60.119,95.8C77.816,93.261,91,80.931,91.632,67.46H95.76Z' fill='%2300b0fa' /%3e%3cpath id='Path_8' data-name='Path 8' d='M57.2,87.847a1.459,1.459,0,0,1-1.109-.509,13.976,13.976,0,0,1-2.411-4.007A1.46,1.46,0,1,1,56.4,82.264a11.052,11.052,0,0,0,1.91,3.172A1.46,1.46,0,0,1,57.2,87.848Z' fill='%2300b0fa' /%3e%3cg id='Group_3' data-name='Group 3' transform='translate(-13 -17)'%3e%3ccircle id='Ellipse_3' data-name='Ellipse 3' cx='20' cy='20' r='20' transform='translate(45.913 5.426)' fill='%2300b0fa' /%3e%3c/g%3e%3cpath id='Path_9' data-name='Path 9' d='M29.426,33.809h48v7.513h-48Z' fill='%2300b0fa' /%3e%3c/g%3e%3c/g%3e%3ctext id='_25' data-name='25' transform='translate(266 167)' fill='%23fff' font-size='20' font-family='SegoeUI, Segoe UI' %3e%3ctspan x='1.719' y='22'%3e ${
    index || "00"
  } %3c/tspan%3e%3c/text%3e%3c/g%3e%3c/svg%3e`;
};
